import Apiclient from '../../Helpers/ApiClient';
import { LoginFailed, LoginSuccess, RegisterFailed } from '../Actions/UserActions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Register from '../../Pages/Auth/Register';

export const LoginService = async (dispatch, user, setLoader) => {
    setTimeout(() => {
        Apiclient().post('/login',  user) 
            .then((response) => {
                const res = response.data;
                    const token  = res.token; 
                    var jwt = token;
                    var tokens = jwt.split(".");
                    console.log(JSON.parse(atob(tokens[1])))
                    const username = JSON.parse(atob(tokens[1])).username;
                    localStorage.setItem('user', username)
                    localStorage.setItem('token', token)
                    dispatch(LoginSuccess(username, token))
                    setLoader(false);
                    window.location.replace('/dishes')
            }).catch((e) => { 
                    setLoader(false);
                    const error = e.response.data.message;
                    console.log(e);
                    dispatch(LoginFailed(error))
            })
      }, 2000)
};
 
export const LogoutService  = () => {
    localStorage.clear();
    window.location.replace('/');
}

export const RegisterService = (dispatch, user) => {
    setTimeout(() => {
        Apiclient().post('/users',  user) 
            .then((response) => {
                if (response.status === 200) {
                    const logUser = {
                        username : user.email,
                        password : user.password
                    }
                    return response;
                } 
            }).catch((e) => { 
                    console.log(e.response)
                    const error = e.response.data['hydra:description'];
                    dispatch(RegisterFailed(error))
            })
      }, 2000)
}


