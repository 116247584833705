import { PayerAction } from "../Actions/PayerAction";



const initialState = {
    name: "payers",
    temporalyPayer : null,
    Payers : null,
    error : false,
    message : null,
}





const  PayerReducer = (state = initialState, action) => {
    switch (action.type) {
    case PayerAction.ADD_TEMPORALY_PAYER :
        return {...state, temporalyPayer : action.payload.payer} 
    case PayerAction.ADD_PAYER_ACTION_SUCCESS :
        return {...state, message: action.payload.message } 
    case PayerAction.ADD_PAYER_ACTION_FAILED :
        return {...state, error: action.payload.error } 
    case PayerAction.GET_PAYERS_ACTION_SUCCESS :
        return {...state, payers: action.payload.payers }
    case PayerAction.GET_PAYERS_ACTION_FAILED :
        return {...state, error: action.payload.error } 
    default:
        return {...state};
    }
};

export default PayerReducer;