import { Snackbar, Alert, AlertProps } from '@mui/material';
import * as React from 'react';



 const SnackbarAlert = React.forwardRef<HTMLDivElement, AlertProps>(
    function SnackbarAlert(props, ref) {
        return <Alert elevation={6}  ref={ref} {...props} />
    }
 )

 type Props =  {
    open : boolean,
    message : string,
 }
 const AlertComponentError : React.FC<Props> = ({open, message}) => {
    return (
        <Snackbar open={open} autoHideDuration={6000}>
            <SnackbarAlert  severity="error">
                {message}
            </SnackbarAlert>
        </Snackbar>
    );
 }
export default AlertComponentError
