import { AccountCircle, PhoneAndroid } from '@mui/icons-material';
import { Box, FormControl, InputAdornment, TextField } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';


 const AddField  = () => {
    const {
        register,
        handleSubmit,
        control,
        formState : { errors }
      } = useForm({
        defaultValues: {
            phone: ""
        },
        mode : "onTouched"
      })

 return (
    <Box>
            <FormControl sx={{ display : "flex",  flexDirection : "row", justifyContent : "space-between" , marginBottom : '30px'}}>
                <TextField
                    sx={{ marginBottom : "5px", marginRight : "10px", marginTop : "12px"}}
                    id="input-with-icon-textfield"
                    label="Name"
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <AccountCircle />
                        </InputAdornment>
                    ),
                    }}
                    variant="standard"
                />
                <Controller 
                    render={({ 
                            field: { onChange, value } , 
                            fieldState: { isTouched, isDirty, error }
                    }) => (
                        <MuiPhoneNumber defaultCountry={'cm'} size="small" 
                        sx={{ marginBottom : "5px", marginRight : "10px"}} 
                            preferredCountries={["cm"]}
                            label="Téléphone Mobile"
                            margin="normal"
                            value={value}
                            />
                        )}
                        rules={{ required : false }}
                        name={"phone"}
                        control={control}
                 />
            </FormControl>
        </Box>
  );
}

export default AddField