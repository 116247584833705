import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { isLogin } from '../../../Helpers/Utils';

const PublicRoute = ({component: Component, restricted, path, }) => {
    console.log(localStorage.getItem('token'));
    return (

        <Routes>
            <Route  exact path={path} element={
                isLogin() && restricted ? <Navigate to="/dishes" />
                                         : <Component />
                } />
        </Routes>
    );
};

export default PublicRoute;
