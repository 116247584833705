import React from 'react';
import { useDispatch } from "redux"


export const  PayerAction = {
    ADD_PAYER_ACTION_SUCCESS : "ADD_PAYER_ACTION_SUCCESS" ,
    ADD_PAYER_ACTION_FAILED : "ADD_PAYER_ACTION_FAILED" ,
    ADD_TEMPORALY_PAYER : "ADD_TEMPORALY_PAYER" ,
    REMOVE_PAYER_SUCCESS : "REMOVE_PAYER_SUCCESS" ,
    REMOVE_PAYER_FAILED : "REMOVE_PAYER_FAILED" ,
    GET_PAYERS_ACTION_SUCCESS : "GET_PAYERS_ACTION_SUCCESS", 
    GET_PAYERS_ACTION_FAILED : "GET_PAYERS_ACTION_FAILED", 
}


export const addTemporalyPayer = (payer) => ({
        type : PayerAction.ADD_TEMPORALY_PAYER,
        payload : {payer : payer}
});


export const addPayerSucess = (message) => ({
    type : PayerAction.ADD_PAYER_ACTION_SUCCESS,
    payload : {message : message}
});

export const addPayerFailed = (error) => ({
    type : PayerAction.ADD_PAYER_ACTION_FAILED,
    payload : {error : error}
})

export const removePayerSuccess = (message) => ({
    type : PayerAction.REMOVE_PAYER_SUCCESS,
    payload : {message : message}
})

export const removePayerFailed = (error) => ({
    type : PayerAction.REMOVE_PAYER_FAILED,
    payload : {error : error}
})

export const getPayerFailed = (error) => ({
    type : PayerAction.GET_PAYERS_ACTION_FAILED,
    payload : {error : error}
})

export const getPayerSuccess = (payers) => ({
    type : PayerAction.GET_PAYERS_ACTION_SUCCESS,
    payload : {payers : payers}
})