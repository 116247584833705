import {  BrunchDining, FoodBankRounded, LocalBar, Logout, LunchDining, PersonAdd, Restaurant, Settings,  } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React, {useState} from 'react';
import {  useNavigate } from 'react-router-dom';
import { LogoutService } from '../Redux/Services/UserService';
import DrawerComponent from './DrawerComponent';
import DrawerCommand from './SemiComponents/DrawerCommand';
import logo from "./../Assets/Images/logo.png";
import AddPayer from './SemiComponents/Dialog/AddPayer';



function  Navigation() {
    const navigate =   useNavigate();
    const  [openDrawer, setOpenDrawer] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addPayer, setAddPayer] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const logout  =() =>  {
      LogoutService();
      handleClose();
    }
  return (
   <AppBar position="fixed"  >
        <Toolbar color="transparent" className="navbar" sx={{ backgroundColor : "white", color : "black" }}>
              <img alt="Bad request" style={{ height : "70px", width : "150px" }}  src={logo} />
            
              <Box sx={{width : "100%" }} />
            
            {isMobile && (
               <>
                <Box marginRight={"30px"}>
                  <Button variant="contained" onClick={() => setOpenDrawer(true)} color="warning">Commande</Button>
                </Box>
                <DrawerCommand  open={openDrawer} setOpen={setOpenDrawer}/>
               </>
        ) } 
            <Stack direction="row" justifyContent={"right"} alignItems={"right"} spacing={2} color={"white"}>
                <Tooltip title="Me,u">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> 
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                <MenuItem onClick={() => navigate('/')}  >
                  <Avatar ><LunchDining /> </Avatar> Plats
                </MenuItem>
                <MenuItem onClick={() => navigate('/drinks')}>
                  <Avatar ><LocalBar />  </Avatar>Boissons
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Add another account
                </MenuItem>
                
              </Menu>
            </Stack>
        </Toolbar>
   </AppBar>
  );
}

export default Navigation;
