import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, Controller}  from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { RegisterFailed } from '../../../Redux/Actions/UserActions';
import { LoginService, RegisterService } from '../../../Redux/Services/UserService';
import { Stack } from '@mui/system';



const theme = createTheme();

export default function FormRegister() {

  const {
    register,
    handleSubmit,
    control,
    formState : { errors }
  } = useForm({
    defaultValues: {
        phone: ""
    },
    mode : "onTouched"
  })
  const dispatch  =  useDispatch()
  const [phone, setPhone] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const UserState = useSelector(state => state.user)
  function handleOnChange(value) {
        setPhone(value);
    }
    console.log(errors);

  const Submit = async (data) => {
    setLoader(true);
    if (data.required_password === data.required_password_confirm) {
        const user = {
            username : data.required_username,
            email : data.required_email,
            password : data.required_password,
            phone : phone

        }
        const register = RegisterService(dispatch, user)
        console.log(register);
        if (register.status === 201) {
            LoginService(dispatch, {
                username : user.data.email,
                password : user.password
            })
        }
    } else {
        setLoader(false)
        dispatch(RegisterFailed('Echec de la confirmation du password'))
    }

  };


  return (
    <Grid item xs={12} md={12} sm={12} sx={{ borderTopLeftRadius : "10px", borderBottomLeftRadius : "10px"  }} >
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'warning' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                S'Inscrire
            </Typography>
            {UserState.error ? <Alert severity="error">{UserState.error}</Alert> : ""}
            <Box component="form" onSubmit={handleSubmit(Submit)} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    size="small"
                    type="text"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    {...register('required_username',  {
                        required : true
                    })}
                />
                {errors.required_username && (
                    <small style={{ color : "red"}}>Entrer un nom d'utilisateur </small>
                )}
                <Controller 
                    render={({ 
                            field: { onChange, value } , 
                            fieldState: { isTouched, isDirty, error }
                    }) => (
                        <MuiPhoneNumber defaultCountry={'cm'} size="small" fullWidth 
                            preferredCountries={["cm"]}
                            label="Téléphone Mobile"
                            margin="normal"
                            variant="outlined" 
                            value={value}
                            onChange={handleOnChange}
                            />
                        )}
                        rules={{ required : false }}
                        name={"phone"}
                        control={control}
                 />
                 {errors.phone && (
                    <small style={{ color : "red"}}>Entrer votre numéro de téléphone </small>
                )}
                <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                size="small"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                {...register('required_email',  {
                    required : true
                })}
                />
                {errors.required_email && (
                    <small style={{ color : "red"}}>Entrer votre email</small>
                )}
                <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                size="small"
                autoComplete="current-password"
                {...register('required_password',  {
                    required : true
                })}
                />
                {errors.required_password && (
                    <small style={{ color : "red"}}>Entrer un mot de passe</small>
                )}
                 <TextField
                margin="normal"
                required
                fullWidth
                name="password_confirm"
                label="Confim password"
                type="password"
                id="password_confirm"
                size="small"
                autoComplete="current-password"
                {...register('required_password_confirm',  {
                    required : true
                })}
                />
                {errors.required_password && (
                    <small style={{ color : "red"}}>Confirmer votre mot de passe</small>
                )}
                <Grid item xs>
                    <Button color='warning'   fontWeight="bold" href="/" component={Link}>
                        <Typography textTransform="lowercase">Mot de passe Oublié ?</Typography>
                    </Button>
                </Grid>
                <Button
                type="submit"
                fullWidth
                color="warning"
                onClick={() => handleSubmit(Submit)}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                S'inscrire
                </Button>
                <Box marginBottom={"40px"} align="center">
                    {"Déjà un compte ? "}
                    <Button color='warning'   fontWeight="bold" href="/" component={Link}>
                        <Typography textTransform="capitalize">se connecter</Typography>
                    </Button>
                </Box>
            </Box>
            </Box>
        </Container>
        </ThemeProvider>
    </Grid>
  );
}