import { Paper, Stack } from '@mui/material';
import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import PrivateRoute from './Components/SemiComponents/Route/PrivateRoute';
import PublicRoute from './Components/SemiComponents/Route/PublicRoute';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Command from './Pages/Command';
import Dishes from './Pages/Dishes';
import Drinks from './Pages/Drinks';


function App() {
  return (
    <BrowserRouter>
          <PublicRoute restricted={true} component={Dishes} path="/" />
          <PublicRoute restricted={true} component={Drinks} path="/drinks" />
          
    </BrowserRouter>
  );
}

export default App;
