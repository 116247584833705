import { Grid } from '@mui/material';
import * as React from 'react';
import SkelletonCard from '../Skelleton/SkelletonCard';



export default function LoaderReviewCard() {
    return (
        
        <>
           
            {[...new Array(6)]
            .map(
              () =>    <Grid item md={4} xs={12} sm={6} >
                            <SkelletonCard />
                        </Grid>
            )}

        </>

    );
}