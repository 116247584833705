import { CommandActions } from '../Actions/CommandActions';

const initialState = {
    name: "command",
    type : "",
    command : {
        dishes : [],
        drinks : [],
        payers : [],
    },
    totalPrice : 0,
    error : false,
}



const  CommandReducer = (state = initialState, action) => {
    switch (action.type) {
    case CommandActions.ADD_DIHES_TO_COMMAND_ACTION :
        return {
            name : "command", 
            type: action.payload.type,  
            command : { 
                dishes : [...state.command.dishes,  action.payload.dishes], 
                drinks :  [...state.command.drinks] ,
                payers : [...state.command.payers],
            },
            error: false, 
            totalPrice : state.totalPrice + action.payload.dishes.price } 
    case CommandActions.ADD_PAYERS_AND_TYPE_TO_COMMAND_ACTION :
        return {
            name : "command", 
            type: action.payload.type,  
            command : { 
                dishes : [...state.command.dishes], 
                payers : action.payload.payers,
                drinks :  [...state.command.drinks]
            }, 
            error: false, 
            totalPrice : state.totalPrice + action.payload.dishes.price } 

    case CommandActions.ADD_DRINK_TO_COMMAND_ACTION :
        return {
            name : "command",
            type: action.payload.type,  
            command : { 
                dishes : [...state.command.dishes], 
                drinks :  [...state.command.drinks,  action.payload.drink], 
                payers : [...state.command.payers]
            }, 
            error: false,
            totalPrice : state.totalPrice + action.payload.drink.price,
        } 
    
    case CommandActions.REMOVE_DIHES_TO_COMMAND_ACTION :
        
        const newStateDishes = state.command.dishes.filter((dish) => dish.id !== action.payload.id)
        const numberDishesRemove =  state.command.dishes.filter((dish) => dish.id === action.payload.id).length; 
        return {
            name : "command",
            type: action.payload.type,  
            command : { 
                dishes : [...newStateDishes], 
                drinks :  [...state.command.drinks], 
                payers : [...state.command.payers]
            }, 
            error: false,
            totalPrice : state.totalPrice - action.payload.price * numberDishesRemove,
        } 
    case CommandActions.REMOVE_DRINK_TO_COMMAND_ACTION :
       
        const newStateDrinks = state.command.drinks.filter((drink) => drink.id !== action.payload.id)
        const numberDrinksRemove =  state.command.drinks.filter((drink) => drink.id === action.payload.id).length; 
        return {
            name : "command",
            type: action.payload.type,  
            command : { 
                dishes : [...state.command.dishes], 
                drinks :  [...newStateDrinks], 
                payers : [...state.command.payers]
            }, 
            error: false,
            totalPrice : state.totalPrice - action.payload.price * numberDrinksRemove,
        } 
        
    default:
        return {...state};
    }
};

export default CommandReducer;